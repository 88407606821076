import { createWebHashHistory, createRouter } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
  },
  {
    path: '/xzxm',
    name: 'xzxm',
    component: () => import('@/views/xzxm/xzxm.vue'),
  },
  { //新页面
    path: '/Newpage',
    name: 'Newpage',
    component: () => import('@/views/Newpage/Newpage.vue'),
  },
  // 新模板
  {
    path: '/newTemplate',
    name: 'newTemplate',
    component: () => import('@/views/newTemplate/newTemplate.vue'),
    redirect: '/firstTemplate',
    children: [
      {
        path: '/firstTemplate',
        name: 'firstTemplate',
        component: () => import('@/views/firstTemplate/index.vue'),
      }
    ]
  },

  {
    path: '/nav',
    name: 'nav',
    component: () => import('@/views/index.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index.vue'),
      },
      {
        path: '/qualityControl',
        name: 'qualityControl',
        component: () => import('@/views/qualityControl/index.vue'),
      },
      {
        path: '/safety',
        name: 'safety',
        component: () => import('@/views/safety/index.vue'),
      },
      {
        path: '/sjgl',
        name: 'sjgl',
        component: () => import('@/views/sjgl/index.vue'),
      },
      {
        path: '/cggl',
        name: 'cggl',
        component: () => import('@/views/cggl/index.vue'),
      },
      {
        path: '/progress',
        name: 'progress',
        component: () => import('@/views/progress/index.vue'),
      },
      {
        path: '/costControl',
        name: 'costControl',
        component: () => import('@/views/costControl/index.vue'),
      },
      {
        path: '/Contract',
        name: 'Contract',
        component: () => import('@/views/Contract/index.vue'),
      },
      {
        path: '/investment',
        name: 'investment',
        component: () => import('@/views/investment/index.vue'),
      },
      {
        path: '/xinXiGuanLi',
        name: 'xinXiGuanLi',
        component: () => import('@/views/xinXiGuanLi/index.vue')
      },
      {
        path: '/completion',
        name: 'completion',
        component: () => import('@/views/completion/index.vue')
      },
    ]
  },
  {
    path: '/engineering',
    name: 'engineering',
    component: () => import('@/views/engineering/index.vue'),
  },
  {
    path: '/juecejieduan/:name',
    name: 'juecejieduan',
    component: () => import('@/views/juecejieduan/index.vue')
  },
  {
    path: '/touzijieduan/:name',
    name: 'touzijieduan',
    component: () => import('@/views/touzijieduan/index.vue')
  },
  {
    path: '/IndividualCenter',
    name: 'IndividualCenter',
    component: () => import('@/views/IndividualCenter/index.vue')
  },
  {
    path: '/xiangmuguanli',
    name: 'xiangmuguanli',
    component: () => import('@/views/xiangmuguanli/index.vue')
  },
  // {
  //   path: '/jgjd/:name',
  //   name: 'jgjd',
  //   component: () => import('@/views/jgjd/index.vue')
  // },
  {
    path: '/myTask',
    name: 'myTask',
    component: () => import('@/views/myTask/index.vue'),
  },
  {
    path: '/myTask',
    name: 'myTask',
    component: () => import('@/views/myTask/index.vue'),
  },
]

const router = createRouter({
  // 路由的模式
  history: createWebHashHistory(),
  // 路由规则
  routes
})


export default router
